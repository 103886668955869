import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import BaseClient from "../baseClient";

const servicePrefix = "/reportingSvc/";

class ReportingService extends BaseClient {
  static async fetchData(config, application, options = {}) {
    let response;
    const payload = {
      ...config,
      start: config.start || 0,
      offset: config.offset || 0,
      limit: config.limit || 1000,
      clientIds: (config.clientIds && config.clientIds.length) ? config.clientIds : [`${config.clientId}`],
      vendors: config.vendors || ["facebook", "google", "bing"],
      agencyId: null,
      userId: null,
      sokratiRequestId: null,
      clientId: `${config.clientId}`,
    };
    try {
      response = await this.apiCall(
        {
          url: `${servicePrefix}fetch`,
          method: "POST",
          data: JSON.stringify(payload),
        },
        application,
        {
          retryConfig: {
            TypeError: 1,
          },
          ...options,
        },
      );

      const responseToValidate = response?.data?.results?.sokrati?.[1] || [];
      if (responseToValidate?.length > 0 && responseToValidate.every((val) => val === "NA")) {
        uiAPIMonitor("SEV2", "REPORTING_SERVICE_FETCH_FAILED_WITH_NA", { payload, response });
      }
      return Promise.resolve(response);
    } catch (error) {
      if (!error.isAborted) {
        uiAPIMonitor("SEV2", "REPORTING_SERVICE_FETCH_FAILED", { payload, response, error });
      }
      return Promise.reject(error);
    }
  }
}

export default ReportingService;
