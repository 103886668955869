import { getDomainConfig } from "@onlinesales-ai/domain-config-v2";
import { getLSItem } from "@onlinesales-ai/util-methods-v2";

export const configOverrides = {
  /*
    Development Configs
  */
  "appslocal-dev": {
    name: "qa-marketplace.json",
  },
  monetizebaseConfig: {
    name: "monetizebaseConfig.json",
  },
  "monetize-demo": {
    name: "monetize-sandbox.json",
  },
  "monetize-sandbox": {
    name: "monetize-sandbox.json",
  },
  "staging-apps": {
    name: "mrd-ops.json",
  },
  "staging-v1-admin": {
    name: "zepto-ops.json",
  },
  "samsung": {
    name: "samsung.json",
  },
  "staging-v3-apps": {
    name: "senheng-staging-ops.json",
  },
  "staging-v4-apps": {
    name: "monetize-sandbox.json",
  },
  "staging-v6-apps": {
    name: "monetize-sandbox.json",
  },
  "staging-v8-apps": {
    name: "hyundai.json",
  },
  "staging-v9-apps": {
    name: "hyundai.json",
  },
  "staging-v10-apps": {
    name: "rmn-staging.json",
  },
  "osmos-qa-staging-1": {
    name: "qa-marketplace.json",
  },
  "osmos-qa-staging-2": {
    name: "qa-marketplace.json",
  },
  "osmos-qa-staging-3": {
    name: "qa-marketplace.json",
  },
  "oms-qa-staging-1": {
    name: "tving-dev.json",
  },
  "oms-qa-staging-3": {
    name: "tving-dev.json",
  },
  "osmos-staging-1": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-2": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-3": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-4": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-5": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-6": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-7": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-8": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-9": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-10": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-11": {
    name: "qa-marketplace.json",
  },
  "osmos-staging-12": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-13": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-14": {
    name: "monetize-sandbox.json",
  },
  "osmos-staging-15": {
    name: "monetize-sandbox.json",
  },
  // 1mg Config
  "1mg": {
    name: "1mg.json",
  },
  "1mg-ops": {
    name: "1mg-ops.json",
  },
  "1mg-staging": {
    name: "1mg-staging.json",
  },
  "1mg-brandads": {
    name: "1mg-brandads.json",
  },
  "1mg-staging-brandads": {
    name: "1mg-staging-brandads.json",
  },
  wakefern: {
    name: "wakefern.json",
  },
  "wakefern-staging": {
    name: "wakefern-staging.json",
  },
  "wakefern-ops": {
    name: "wakefern-ops.json",
  },
  "wakefern-staging-ops": {
    name: "wakefern-staging-ops.json",
  },
  lamoda: {
    name: "lamoda.json",
  },
  "lamoda-staging": {
    name: "lamoda-staging.json",
  },
  shopmatic: {
    name: "shopmatic.json",
  },
  "shopmatic-staging": {
    name: "shopmatic-staging.json",
  },
  // Purplle
  "purplle-brandads": {
    name: "purplle-brandads.json",
  },
  "purplle-test": {
    name: "purplle-brandads.json",
  },
  "purplle-staging-brandads": {
    name: "purplle-staging-brandads.json",
  },
  "purplle-staging": {
    name: "purplle-staging.json",
  },
  purplle: {
    name: "purplle.json",
  },
  "purplle-ops": {
    name: "purplle-ops.json",
  },
  "purplle-staging-ops": {
    name: "purplle-staging-ops.json",
  },
  "purplle-v2": {
    name: "purplle-v2.json",
  },
  "purplle-new": {
    name: "purplle.json",
  },
  // Limeroad Config
  limeroad: {
    name: "limeroad.json",
  },
  "limeroad-ops": {
    name: "limeroad-ops.json",
  },
  "limeroad-staging": {
    name: "limeroad-staging.json",
  },
  "limeroad-staging-ops": {
    name: "limeroad-staging-ops.json",
  },
  // Takealot
  takealot: {
    name: "takealot.json",
  },
  "takealot-ops": {
    name: "takealot-ops.json",
  },
  "takealot-staging": {
    name: "takealot-staging.json",
  },
  "takealot-staging-ops": {
    name: "takealot-staging-ops.json",
  },
  "takealot-v2": {
    name: "takealot-ops.json",
  },
  "takealot-new": {
    name: "takealot.json",
  },
  "takealot-brands-ops": {
    name: "takealot-brands-ops.json",
  },
  "takealot-supplier": {
    name: "takealot-supplier.json",
  },
  "takealot-supplier-ops": {
    name: "takealot-supplier-ops.json",
  },
  "takealot-supplier-staging": {
    name: "takealot-supplier-staging.json",
  },
  "takealot-supplier-staging-ops": {
    name: "takealot-supplier-staging-ops.json",
  },

  // Konga
  konga: {
    name: "konga.json",
  },
  "konga-ops": {
    name: "konga-ops.json",
  },
  "konga-v2": {
    name: "konga-ops.json",
  },

  // Zepto
  "zepto": {
    name: "zepto.json",
  },
  "zepto-ops": {
    name: "zepto-ops.json",
  },
  "zepto-staging-ops": {
    name: "zepto-staging-ops.json",
  },
  "zepto-staging": {
    name: "zepto-staging.json",
  },

  //kornerShopee
  "kornershoppe": {
    name: "kornershoppe.json",
  },
  "kornershoppe-ops": {
    name: "kornershoppe-ops.json",
  },
  "kornershoppe-prod": {
    name: "kornershoppe-prod.json",
  },
  "kornershoppe-prod-ops": {
    name: "kornershoppe-prod-ops.json",
  },

  // sharaf
  "sharafdg": {
    name: "sharafdg.json",
  },
  "sharafdg-ops": {
    name: "sharafdg-ops.json",
  },
  "sharafdg-staging": {
    name: "sharafdg-staging.json",
  },
  "sharafdg-staging-ops": {
    name: "sharafdg-staging-ops.json",
  },

  // sharaf bahrain
  "sharafdg-bahrain-staging": {
    name: "sharafdg-bahrain-staging.json",
  },
  "sharafdg-bahrain": {
    name: "sharafdg-bahrain.json",
  },

  // glowroad
  "glowroad": {
    name: "glowroad.json",
  },
  "glowroad-ops": {
    name: "glowroad-ops.json",
  },
  "glowroad-staging": {
    name: "glowroad-staging.json",
  },
  "glowroad-staging-ops": {
    name: "glowroad-staging-ops.json",
  },

  // snapdeal
  "snapdeal": {
    name: "snapdeal.json",
  },
  "snapdeal-ops": {
    name: "snapdeal-ops.json",
  },
  "snapdeal-staging": {
    name: "snapdeal-staging.json",
  },
  "snapdeal-staging-ops": {
    name: "snapdeal-staging-ops.json",
  },

  //inmar domains
  "inmarads-demo": {
    name: "inmarads-demo.json",
  },
  "inmarads-rmn": {
    name: "inmarads-rmn.json",
  },
  "inmarads-retailer": {
    name: "inmarads-retailer.json",
  },
  "demo-retailcloud.inmar.com": {
    name: "inmarads-demo.json",
  },

  // PnP Domains
  "retailmedia-qa.pnpiq.co.za": {
    name: "picknpay-staging.json",
  },
  "retailmedia.pnpiq.co.za": {
    name: "picknpay.json",
  },

  // rmn
  "rmn-staging": {
    name: "rmn-staging.json",
  },
  "retailium-sandbox": {
    name: "retailium-sandbox.json",
  },
  "ads.retailcloud.inmar.com": {
    name: "rmn-retailcloud.json",
  },

  // RMN Infibeam Demo Domains
  "iaooo": {
    name: "iaooo.json",
  },
  "iaooo-ops": {
    name: "iaooo-ops.json",
  },
  "retail-cloud.ia.ooo": {
    name: "iaooo.json",
  },
  "retail-cloud-ops.ia.ooo": {
    name: "iaooo-ops.json",
  },

  // Mr. D
  "mrd": {
    name: "mrd.json",
  },
  "mrd-ops": {
    name: "mrd-ops.json",
  },
  "mrd-staging": {
    name: "mrd-staging.json",
  },
  "mrd-staging-ops": {
    name: "mrd-staging-ops.json",
  },

  // Fanatics
  "fanatics": {
    name: "fanatics.json",
  },
  "fanatics-staging": {
    name: "fanatics-staging.json",
  },

  "target-ops": {
    name: "target-ops.json",
  },

  // Pick n Pay
  "picknpay": {
    name: "picknpay.json",
  },
  "picknpay-staging": {
    name: "picknpay-staging.json",
  },
  "picknpay-ops": {
    name: "picknpay-ops.json",
  },
  "picknpay-staging-ops": {
    name: "picknpay-staging-ops.json",
  },

  // Senheng
  "senheng": {
    name: "senheng.json",
  },
  "senheng-staging": {
    name: "senheng-staging.json",
  },
  "senheng-ops": {
    name: "senheng-ops.json",
  },
  "senheng-staging-ops": {
    name: "senheng-staging-ops.json",
  },

  // FirstCry

  "firstcry": {
    name: "firstcry.json",
  },
  "firstcry-staging": {
    name: "firstcry-staging.json",
  },
  "firstcry-ops": {
    name: "firstcry-ops.json",
  },
  "firstcry-staging-ops": {
    name: "firstcry-staging-ops.json",
  },

  // Dawaa Dost

  "dawaadost": {
    name: "dawaadost.json",
  },
  "dawaadost-staging": {
    name: "dawaadost-staging.json",
  },
  "dawaadost-ops": {
    name: "dawaadost-ops.json",
  },
  "dawaadost-staging-ops": {
    name: "dawaadost-staging-ops.json",
  },

  // Tving

  "oms.tving.com": {
    name: "tving-prod.json",
  },
  "tving-prod": {
    name: "tving-prod.json",
  },
  "tving-prod-staging": {
    name: "tving-prod-staging.json",
  },

  // Tving QA
  "oms-dev.tving.com": {
    name: "tving-qa.json",
  },
  "tving-qa": {
    name: "tving-qa.json",
  },

  // Tving Dev
  "tving-dev": {
    name: "tving-dev.json",
  },

  // Superbalist

  "superbalist": {
    name: "superbalist.json",
  },
  "superbalist-staging": {
    name: "superbalist-staging.json",
  },
  "superbalist-ops": {
    name: "superbalist-ops.json",
  },
  "superbalist-staging-ops": {
    name: "superbalist-staging-ops.json",
  },

  // Ajio

  "ajio": {
    name: "ajio.json",
  },
  "ajio-staging": {
    name: "ajio-staging.json",
  },
  "ajio-ops": {
    name: "ajio-ops.json",
  },
  "ajio-staging-ops": {
    name: "ajio-staging-ops.json",
  },

  // Tesco

  "tesco": {
    name: "tesco.json",
  },
  "tesco-staging": {
    name: "tesco-staging.json",
  },
  "tesco-ops": {
    name: "tesco-ops.json",
  },
  "tesco-staging-ops": {
    name: "tesco-staging-ops.json",
  },

  // dunnhumby-uk

  "dunnhumby-uk": {
    name: "dunnhumby-uk.json",
  },
  "dunnhumby-uk-staging": {
    name: "dunnhumby-uk-staging.json",
  },
  "dunnhumby-uk-ops": {
    name: "dunnhumby-uk-ops.json",
  },
  "dunnhumby-uk-staging-ops": {
    name: "dunnhumby-uk-staging-ops.json",
  },

  // dunnhumby-us

  "dunnhumby-us": {
    name: "dunnhumby-us.json",
  },
  "dunnhumby-us-staging": {
    name: "dunnhumby-us-staging.json",
  },
  "dunnhumby-us-ops": {
    name: "dunnhumby-us-ops.json",
  },
  "dunnhumby-us-staging-ops": {
    name: "dunnhumby-us-staging-ops.json",
  },

  // northeast-grocery

  "northeast-grocery": {
    name: "northeast-grocery.json",
  },
  "northeast-grocery-staging": {
    name: "northeast-grocery-staging.json",
  },
  "northeast-grocery-ops": {
    name: "northeast-grocery-ops.json",
  },
  "northeast-grocery-staging-ops": {
    name: "northeast-grocery-staging-ops.json",
  },

  "os-staging-internal": {
    name: "os-staging-internal.json",
  },

  // konvy

  "konvy": {
    name: "konvy.json",
  },
  "konvy-ops": {
    name: "konvy-ops.json",
  },
  "konvy-staging": {
    name: "konvy-staging.json",
  },
  "konvy-staging-ops": {
    name: "konvy-staging-ops.json",
  },

  // qa grocery

  "qa-grocery": {
    name: "qa-grocery.json",
  },

  // qa marketplace

  "qa-marketplace": {
    name: "qa-marketplace.json",
  },

  // Rapido

  "rapido": {
    name: "rapido.json",
  },
  "rapido-staging": {
    name: "rapido-staging.json",
  },
  "rapido-ops": {
    name: "rapido-ops.json",
  },
  "rapido-staging-ops": {
    name: "rapido-staging-ops.json",
  },

  // Croma

  "croma": {
    name: "croma.json",
  },
  "croma-staging": {
    name: "croma-staging.json",
  },
  "croma-ops": {
    name: "croma-ops.json",
  },
  "croma-staging-ops": {
    name: "croma-staging-ops.json",
  },

  // Truemeds

  "truemeds": {
    name: "truemeds.json",
  },
  "truemeds-staging": {
    name: "truemeds-staging.json",
  },
  "truemeds-ops": {
    name: "truemeds-ops.json",
  },
  "truemeds-staging-ops": {
    name: "truemeds-staging-ops.json",
  },

  // Tata Cliq
  "tatacliq": {
    name: "tatacliq.json",
  },
  "tatacliq-staging": {
    name: "tatacliq-staging.json",
  },
  "tatacliq-ops": {
    name: "tatacliq-ops.json",
  },
  "tatacliq-staging-ops": {
    name: "tatacliq-staging-ops.json",
  },

  // Lidl
  "lidl": {
    name: "lidl.json",
  },
  "lidl-staging": {
    name: "lidl-staging.json",
  },
  "lidl-ops": {
    name: "lidl-ops.json",
  },
  "lidl-staging-ops": {
    name: "lidl-staging-ops.json",
  },

  // Apollo 247
  "apollo-247": {
    name: "apollo-247.json",
  },
  "apollo-247-staging": {
    name: "apollo-247-staging.json",
  },
  "apollo-247-ops": {
    name: "apollo-247-ops.json",
  },
  "apollo-247-staging-ops": {
    name: "apollo-247-staging-ops.json",
  },

  // Medibuddy
  "medibuddy": {
    name: "medibuddy.json",
  },
  "medibuddy-staging": {
    name: "medibuddy-staging.json",
  },
  "medibuddy-ops": {
    name: "medibuddy-ops.json",
  },
  "medibuddy-staging-ops": {
    name: "medibuddy-staging-ops.json",
  },

  // Homepro
  "homepro": {
    name: "homepro.json",
  },
  "homepro-staging": {
    name: "homepro-staging.json",
  },
  "homepro-ops": {
    name: "homepro-ops.json",
  },
  "homepro-staging-ops": {
    name: "homepro-staging-ops.json",
  },

  // Loot
  "loot": {
    name: "loot.json",
  },
  "loot-staging": {
    name: "loot-staging.json",
  },
  "loot-ops": {
    name: "loot-ops.json",
  },
  "loot-staging-ops": {
    name: "loot-staging-ops.json",
  },

  // Workhuman
  "workhuman": {
    name: "workhuman.json",
  },
  "workhuman-staging": {
    name: "workhuman-staging.json",
  },
  "workhuman-ops": {
    name: "workhuman-ops.json",
  },
  "workhuman-staging-ops": {
    name: "workhuman-staging-ops.json",
  },

  // advertiser

  "advertiser": {
    name: "advertiser.json",
  },
  "advertiser-staging": {
    name: "advertiser-staging.json",
  },
  "advertiser-ops": {
    name: "advertiser-ops.json",
  },
  "advertiser-staging-ops": {
    name: "advertiser-staging-ops.json",
  },

  // tata digital

  "tata-digital": {
    name: "tata-digital.json",
  },
  "tata-digital-staging": {
    name: "tata-digital-staging.json",
  },
  "tata-digital-ops": {
    name: "tata-digital-ops.json",
  },
  "tata-digital-staging-ops": {
    name: "tata-digital-staging-ops.json",
  },

};

const configs = {
  "monetizebaseConfig.json": () => import("./configs/monetizebaseConfig"),
  "appslocal-dev.json": () => import("./configs/appslocal-dev"),
  "1mg.json": () => import("./configs/1mg"),
  "monetize-sandbox.json": () => import("./configs/monetize-sandbox"),
  "osmos-sandbox.json": () => import("./configs/osmos-sandbox"),
  "1mg-ops.json": () => import("./configs/1mg-ops"),
  "1mg-staging.json": () => import("./configs/1mg-staging"),
  "1mg-brandads.json": () => import("./configs/1mg-brandads"),
  "1mg-staging-brandads.json": () => import("./configs/1mg-staging-brandads"),
  "wakefern.json": () => import("./configs/wakefern"),
  "wakefern-staging.json": () => import("./configs/wakefern-staging"),
  "wakefern-ops.json": () => import("./configs/wakefern-ops"),
  "wakefern-staging-ops.json": () => import("./configs/wakefern-staging-ops"),
  "lamoda.json": () => import("./configs/lamoda"),
  "lamoda-staging.json": () => import("./configs/lamoda-staging"),
  "samsung.json": () => import("./configs/samsung"),
  "shopmatic.json": () => import("./configs/shopmatic"),
  "shopmatic-staging.json": () => import("./configs/shopmatic-staging"),
  "purplle-brandads.json": () => import("./configs/purplle-brandads"),
  "purplle-staging-brandads.json": () => import("./configs/purplle-staging-brandads"),
  "purplle.json": () => import("./configs/purplle"),
  "purplle-ops.json": () => import("./configs/purplle-ops"),
  "purplle-v2.json": () => import("./configs/purplle-v2"),
  "purplle-staging.json": () => import("./configs/purplle-staging"),
  "purplle-staging-ops.json": () => import("./configs/purplle-staging-ops"),
  "takealot.json": () => import("./configs/takealot"),
  "takealot-ops.json": () => import("./configs/takealot-ops"),
  "takealot-staging.json": () => import("./configs/takealot-staging"),
  "takealot-staging-ops.json": () => import("./configs/takealot-staging-ops"),
  "takealot-brands-ops.json": () => import("./configs/takealot-brands-ops"),
  "takealot-supplier.json": () => import("./configs/takealot-supplier"),
  "takealot-supplier-ops.json": () => import("./configs/takealot-supplier-ops"),
  "takealot-supplier-staging.json": () => import("./configs/takealot-supplier-staging"),
  "takealot-supplier-staging-ops.json": () => import("./configs/takealot-supplier-staging-ops"),
  "konga.json": () => import("./configs/konga"),
  "konga-ops.json": () => import("./configs/konga-ops"),
  "limeroad.json": () => import("./configs/limeroad"),
  "limeroad-ops.json": () => import("./configs/limeroad-ops"),
  "limeroad-staging.json": () => import("./configs/limeroad-staging"),
  "limeroad-staging-ops.json": () => import("./configs/limeroad-staging-ops"),
  "zepto.json": () => import("./configs/zepto"),
  "zepto-ops.json": () => import("./configs/zepto-ops"),
  "zepto-staging.json": () => import("./configs/zepto-staging"),
  "zepto-staging-ops.json": () => import("./configs/zepto-staging-ops"),
  "kornershoppe.json": () => import("./configs/kornershoppe"),
  "kornershoppe-prod.json": () => import("./configs/kornershoppe-prod"),
  "kornershoppe-ops.json": () => import("./configs/kornershoppe-ops"),
  "kornershoppe-prod-ops.json": () => import("./configs/kornershoppe-prod-ops"),
  "sharafdg.json": () => import("./configs/sharafdg"),
  "sharafdg-staging.json": () => import("./configs/sharafdg-staging"),
  "sharafdg-ops.json": () => import("./configs/sharafdg-ops"),
  "sharafdg-staging-ops.json": () => import("./configs/sharafdg-staging-ops"),
  "sharafdg-bahrain-staging.json": () => import("./configs/sharafdg-bahrain-staging"),
  "sharafdg-bahrain.json": () => import("./configs/sharafdg-bahrain"),
  "glowroad.json": () => import("./configs/glowroad"),
  "glowroad-ops.json": () => import("./configs/glowroad-ops"),
  "glowroad-staging.json": () => import("./configs/glowroad-staging"),
  "glowroad-staging-ops.json": () => import("./configs/glowroad-staging-ops"),
  "snapdeal.json": () => import("./configs/snapdeal"),
  "snapdeal-staging.json": () => import("./configs/snapdeal-staging"),
  "snapdeal-ops.json": () => import("./configs/snapdeal-ops"),
  "snapdeal-staging-ops.json": () => import("./configs/snapdeal-staging-ops"),
  "inmarads-demo.json": () => import("./configs/inmarads-demo"),
  "inmarads-rmn.json": () => import("./configs/inmarads-rmn"),
  "rmn-retailcloud.json": () => import("./configs/rmn-retailcloud"),
  "iaooo.json": () => import("./configs/iaooo"),
  "iaooo-ops.json": () => import("./configs/iaooo-ops"),
  "inmarads-retailer.json": () => import("./configs/inmarads-retailer"),
  "rmn-staging.json": () => import("./configs/rmn-staging"),
  "retailium-sandbox.json": () => import("./configs/retailium-sandbox"),
  "mrd.json": () => import("./configs/mrd"),
  "mrd-ops.json": () => import("./configs/mrd-ops"),
  "mrd-staging.json": () => import("./configs/mrd-staging"),
  "mrd-staging-ops.json": () => import("./configs/mrd-staging-ops"),
  "fanatics.json": () => import("./configs/fanatics"),
  "fanatics-staging.json": () => import("./configs/fanatics-staging"),
  "target-ops.json": () => import("./configs/target-ops"),
  "picknpay.json": () => import("./configs/picknpay"),
  "picknpay-staging.json": () => import("./configs/picknpay-staging"),
  "picknpay-ops.json": () => import("./configs/picknpay-ops"),
  "picknpay-staging-ops.json": () => import("./configs/picknpay-staging-ops"),
  "senheng.json": () => import("./configs/senheng"),
  "senheng-staging.json": () => import("./configs/senheng-staging"),
  "senheng-ops.json": () => import("./configs/senheng-ops"),
  "senheng-staging-ops.json": () => import("./configs/senheng-staging-ops"),
  "firstcry.json": () => import("./configs/firstcry"),
  "firstcry-staging.json": () => import("./configs/firstcry-staging"),
  "firstcry-ops.json": () => import("./configs/firstcry-ops"),
  "firstcry-staging-ops.json": () => import("./configs/firstcry-staging-ops"),
  "dawaadost.json": () => import("./configs/dawaadost"),
  "dawaadost-staging.json": () => import("./configs/dawaadost-staging"),
  "dawaadost-ops.json": () => import("./configs/dawaadost-ops"),
  "dawaadost-staging-ops.json": () => import("./configs/dawaadost-staging-ops"),
  "tving-prod.json": () => import("./configs/tving-prod"),
  "tving-prod-staging.json": () => import("./configs/tving-prod-staging"),
  "tving-qa.json": () => import("./configs/tving-qa"),
  "tving-dev.json": () => import("./configs/tving-dev"),
  "superbalist.json": () => import("./configs/superbalist"),
  "superbalist-staging.json": () => import("./configs/superbalist-staging"),
  "superbalist-ops.json": () => import("./configs/superbalist-ops"),
  "superbalist-staging-ops.json": () => import("./configs/superbalist-staging-ops"),
  "ajio.json": () => import("./configs/ajio"),
  "ajio-staging.json": () => import("./configs/ajio-staging"),
  "ajio-ops.json": () => import("./configs/ajio-ops"),
  "ajio-staging-ops.json": () => import("./configs/ajio-staging-ops"),
  "tesco.json": () => import("./configs/tesco"),
  "tesco-staging.json": () => import("./configs/tesco-staging"),
  "tesco-ops.json": () => import("./configs/tesco-ops"),
  "tesco-staging-ops.json": () => import("./configs/tesco-staging-ops"),
  "dunnhumby-uk.json": () => import("./configs/dunnhumby-uk"),
  "dunnhumby-uk-staging.json": () => import("./configs/dunnhumby-uk-staging"),
  "dunnhumby-uk-ops.json": () => import("./configs/dunnhumby-uk-ops"),
  "dunnhumby-uk-staging-ops.json": () => import("./configs/dunnhumby-uk-staging-ops"),
  "dunnhumby-us.json": () => import("./configs/dunnhumby-us"),
  "dunnhumby-us-staging.json": () => import("./configs/dunnhumby-us-staging"),
  "dunnhumby-us-ops.json": () => import("./configs/dunnhumby-us-ops"),
  "dunnhumby-us-staging-ops.json": () => import("./configs/dunnhumby-us-staging-ops"),
  "northeast-grocery.json": () => import("./configs/northeast-grocery"),
  "northeast-grocery-staging.json": () => import("./configs/northeast-grocery-staging"),
  "northeast-grocery-ops.json": () => import("./configs/northeast-grocery-ops"),
  "northeast-grocery-staging-ops.json": () => import("./configs/northeast-grocery-staging-ops"),
  "os-staging-internal.json": () => import("./configs/os-staging-internal"),
  "konvy.json": () => import("./configs/konvy"),
  "konvy-ops.json": () => import("./configs/konvy-ops"),
  "konvy-staging.json": () => import("./configs/konvy-staging"),
  "konvy-staging-ops.json": () => import("./configs/konvy-staging-ops"),
  "qa-grocery.json": () => import("./configs/qa-grocery"),
  "qa-marketplace.json": () => import("./configs/qa-marketplace"),
  "rapido.json": () => import("./configs/rapido"),
  "rapido-staging.json": () => import("./configs/rapido-staging"),
  "rapido-ops.json": () => import("./configs/rapido-ops"),
  "rapido-staging-ops.json": () => import("./configs/rapido-staging-ops"),
  "croma.json": () => import("./configs/croma"),
  "croma-staging.json": () => import("./configs/croma-staging"),
  "croma-ops.json": () => import("./configs/croma-ops"),
  "croma-staging-ops.json": () => import("./configs/croma-staging-ops"),
  "truemeds.json": () => import("./configs/truemeds"),
  "truemeds-staging.json": () => import("./configs/truemeds-staging"),
  "truemeds-ops.json": () => import("./configs/truemeds-ops"),
  "truemeds-staging-ops.json": () => import("./configs/truemeds-staging-ops"),
  "tatacliq.json": () => import("./configs/tatacliq"),
  "tatacliq-staging.json": () => import("./configs/tatacliq-staging"),
  "tatacliq-ops.json": () => import("./configs/tatacliq-ops"),
  "tatacliq-staging-ops.json": () => import("./configs/tatacliq-staging-ops"),
  "lidl.json": () => import("./configs/lidl"),
  "lidl-staging.json": () => import("./configs/lidl-staging"),
  "lidl-ops.json": () => import("./configs/lidl-ops"),
  "lidl-staging-ops.json": () => import("./configs/lidl-staging-ops"),
  "apollo-247.json": () => import("./configs/apollo-247"),
  "apollo-247-staging.json": () => import("./configs/apollo-247-staging"),
  "apollo-247-ops.json": () => import("./configs/apollo-247-ops"),
  "apollo-247-staging-ops.json": () => import("./configs/apollo-247-staging-ops"),
  "medibuddy.json": () => import("./configs/medibuddy"),
  "medibuddy-staging.json": () => import("./configs/medibuddy-staging"),
  "medibuddy-ops.json": () => import("./configs/medibuddy-ops"),
  "medibuddy-staging-ops.json": () => import("./configs/medibuddy-staging-ops"),
  "homepro.json": () => import("./configs/homepro"),
  "homepro-staging.json": () => import("./configs/homepro-staging"),
  "homepro-ops.json": () => import("./configs/homepro-ops"),
  "homepro-staging-ops.json": () => import("./configs/homepro-staging-ops"),
  "loot.json": () => import("./configs/loot"),
  "loot-staging.json": () => import("./configs/loot-staging"),
  "loot-ops.json": () => import("./configs/loot-ops"),
  "loot-staging-ops.json": () => import("./configs/loot-staging-ops"),
  "workhuman.json": () => import("./configs/workhuman"),
  "workhuman-staging.json": () => import("./configs/workhuman-staging"),
  "workhuman-ops.json": () => import("./configs/workhuman-ops"),
  "workhuman-staging-ops.json": () => import("./configs/workhuman-staging-ops"),
  "advertiser.json": () => import("./configs/advertiser"),
  "advertiser-staging.json": () => import("./configs/advertiser-staging"),
  "advertiser-ops.json": () => import("./configs/advertiser-ops"),
  "advertiser-staging-ops.json": () => import("./configs/advertiser-staging-ops"),
  "tata-digital.json": () => import("./configs/tata-digital"),
  "tata-digital-staging.json": () => import("./configs/tata-digital-staging"),
  "tata-digital-ops.json": () => import("./configs/tata-digital-ops"),
  "tata-digital-staging-ops.json": () => import("./configs/tata-digital-staging-ops"),
};

const stagingDomains = [
  "staging-v1-admin.onlinesales.ai",
  "appslocal-dev.onlinesales.ai",
  "staging-v3-apps.onlinesales.ai",
  "staging-apps.onlinesales.ai",
  "staging-v4-apps.onlinesales.ai",
  "staging-v6-apps.onlinesales.ai",
  "staging-v8-apps.onlinesales.ai",
  "staging-v9-apps.onlinesales.ai",
  "staging-v10-apps.onlinesales.ai",
  "osmos-staging-1.onlinesales.ai",
  "osmos-staging-2.onlinesales.ai",
  "osmos-staging-3.onlinesales.ai",
  "osmos-staging-4.onlinesales.ai",
  "osmos-staging-5.onlinesales.ai",
  "osmos-staging-6.onlinesales.ai",
  "osmos-staging-7.onlinesales.ai",
  "osmos-staging-8.onlinesales.ai",
  "osmos-staging-9.onlinesales.ai",
  "osmos-staging-10.onlinesales.ai",
  "osmos-staging-11.onlinesales.ai",
  "osmos-staging-12.onlinesales.ai",
  "osmos-staging-13.onlinesales.ai",
  "osmos-staging-14.onlinesales.ai",
  "osmos-staging-15.onlinesales.ai",
  "osmos-qa-staging-1.onlinesales.ai",
  "osmos-qa-staging-2.onlinesales.ai",
  "osmos-qa-staging-3.onlinesales.ai",
  "oms-qa-staging-1.onlinesales.ai",
  "oms-qa-staging-3.onlinesales.ai",
  "osmos-staging-1.onlinesales.ai",
];

export const isStagingDomain = stagingDomains.includes(window?.location?.hostname);

export const lngDomains = [
  ...stagingDomains,
  "oms.tving.com",
  "oms-dev.tving.com",
  "tving-prod.onlinesales.ai",
  "tving-prod-staging.onlinesales.ai",
  "tving-qa.onlinesales.ai",
  "tving-dev.onlinesales.ai",
  "oms-qa-staging-1.onlinesales.ai",
  "oms-qa-staging-3.onlinesales.ai",
  "osmos-sandbox.onlinesales.ai",
  "monetize-sandbox.onlinesales.ai",
];

export const isLngDomain = lngDomains.includes(window?.location?.hostname);

export const getDomainConfiguration = ({ clientConfigMapping = {} }) => {
  const domain = window.location.hostname;
  const domainParts = domain.split(".onlinesales.ai");
  const params = new URLSearchParams(window.location.search);
  let configName = params.has("configName") ? params.get("configName") : null;

  if (isStagingDomain && getLSItem("m-configName")) {
    configName = getLSItem("m-configName");
  }

  return getDomainConfig({
    configIdentifier: configName || domainParts[0],
    defaultConfigName: "base.json",
    configOverrides,
    clientConfigMapping,
    configs,
  });
};
