export const DASHBOARD_COMPONENT = {
  FUNNEL: "funnel",
  SUMMARY_CHART: "summaryChart",
  DASHBOARD_HEADER: "dashboardHeader",
  FUNNEL_SUMMARY_COMBO: "funnelSummaryCombo",
  PROGRAM_CONTRIBUTION: "programContribution",
  CHANNEL_TREE_MAP: "channelTreeMap",
  CLIENT_PERFORMANCE: "clientPerformance",
  INDUSTRY_BENCHMARK: "industryBenchmark",
  INDUSTRY_BENCHMARK_V2: "industryBenchmarkV2",
  TOP_PRODUCTS: "topProducts",
  TOP_CATEGORIES: "topCategories",
  TOP_PRODUCTS_TABLE: "topProductsTable",
  KEYWORDS: "topKeywords",
  BID_CONFIGURATION: "bidConfiguration",
  GOALS_LIST_PERFORMANCE: "goalsListPerformance",
  RMN_CHANNEL_LEVEL_REPORT: "rmnChannelLevelReport",
  GOALS_LIST_INVENTORY: "goalsListInventory",
  CLIENT_ACHIEVEMENT: "clientAchievements",
  CONSOLIDATED_GOALS_LIST: "consolidatedGoalsList",
  TOP_TEXT_ADS: "topTextAds",
  TOP_FACEBOOK_ADS: "topFacebookAds",
  CLIENT_LEVEL_REPORTING: "clientLevelReporting",
  TOP_CALL_ADS: "topCallAds",
  TOP_RESPONSIVE_ADS: "topResponsiveAds",
  INVENTORY_PERFORMANCE_SUMMARY: "inventoryPerformanceSummary",
  GOALS_LIST_ALL: "goalsListAll",
  POSTS_ACTIVITY: "postsActivity",
  USER_REVIEW_COMMENTS: "userReviewComments",
  SMM_POSTS_LIST: "smmPostsList",
  USER_REVIEW_COMMENT_ANALYSIS: "userReviewCommentAnalysis",
  SENTIMENT_ANALYSIS: "sentimentAnalysis",
  EMAIL_GOALS: "emailGoals",
  WHATSAPP_GOALS: "whatsAppGoals",
  RMN_CLIENT_PERFORMANCE: "rmnClientPerformance",
  FOOTER: "footer",
};

export const FilterComponentTypes = {
  CHECKBOX: "CHECKBOX",
  RADIOBOX: "RADIOBOX",
  DATE: "DATE",
  CUSTOM: "CUSTOM",
  RANGE_FILTER: "RANGE_FILTER",
  CONTAINS_TEXT: "CONTAINS_TEXT",
};

export const NotificationTypesEnum = {
  HEADER_UI: "header",
  ANNOUNCEMENT_BOARD: "announcementBoard",
};
